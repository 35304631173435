@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    [type='text'],
    [type='email'],
    [type='url'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple],
    textarea,
    select {
        @apply w-full border border-gray-300 rounded-lg shadow-sm focus:border-primary-500 focus:ring-primary-500 focus:invalid:border-red-500 focus:invalid:ring-red-500 
    }
}


.th:nth-child(1),.td:nth-child(1),
.th:nth-child(2),.td:nth-child(2) {
    width: 50px !important;
}

.th:nth-child(9),.td:nth-child(9) {
    width: 200px !important;
}